import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const audienceSlice = createSlice({

	name: 'audience',
	initialState,
	reducers: {
		setAudienceList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		updateAudienceItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		addAudienceItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeAudienceItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setAudienceLoading: (state, action) => {
			state.loading = action.payload;
		}
	}

});

export const { setAudienceList, updateAudienceItem, 
	addAudienceItem, removeAudienceItem, 
	setAudienceLoading } = audienceSlice.actions;


export const fetchAudienceList = (payload = {}) => async (dispatch) => {
	try {

		dispatch(setAudienceLoading(true));

		const res = await api.audienceList(payload);
		dispatch(setAudienceList(res));

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось получить список клиентов'
		}));

		return Promise.reject(error);
		
	} finally {

		dispatch(setAudienceLoading(false));

	}
};

export default audienceSlice.reducer;
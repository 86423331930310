import { createSlice } from '@reduxjs/toolkit';
import { showErrorToast } from './toast';
import api from 'src/api/index';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const purchasesSlice = createSlice({
	name: 'purchases',
	initialState,
	reducers: {
		setPurchasesList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		addPurchaseItem: (state, action) => {
			state.list.push(action.payload);
		},
		removePurchaseItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setPurchaseLoading: (state, action) => {
			state.loading = action.payload;
		}
		
	}
});

export const { setPurchasesList,  addPurchaseItem, removePurchaseItem, setPurchaseLoading } = purchasesSlice.actions;

export const getPurchasesList = (sendData = {}) => async (dispatch) => {
	
	try {

		dispatch(setPurchaseLoading(true));
		const res = await api.purchasesList(sendData);
		dispatch(setPurchasesList(res));
		
	} catch (error) {

		console.error(error);
		dispatch(showErrorToast({
			description: 'Не удалось получить список покупок автора'
		}));

	} finally {

		dispatch(setPurchaseLoading(false));

	}

};

export default purchasesSlice.reducer;
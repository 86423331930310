import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Register = React.lazy(() => import('./pages/register/RegisterStepOne'));
const RegisterStepTwo = React.lazy(() => import('./pages/register/RegisterStepTwo'));
const ForgotPassword = React.lazy(() => import('./pages/recovery-password/ForgotPassword'));
const RecoveryPassword = React.lazy(() => import('./pages/recovery-password/RecoveryPassword'));

const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));


class App extends Component {
	render() {
		return (
			<HashRouter>
				<Suspense fallback={loading}>
					<Routes>
						<Route exact path="/login" name="Login Page" element={<Login />} />
						<Route exact path="/register" name="Register Page" element={<Register />} />
						<Route exact path="/register-step-two" name="Register-2" element={<RegisterStepTwo />} />
						<Route exact path="/forgot-password" element={<ForgotPassword />} />
						<Route exact path="/recovery-password" element={<RecoveryPassword />} />
						<Route exact path="/404" name="Page 404" element={<Page404 />} />
						<Route exact path="/500" name="Page 500" element={<Page500 />} />
						<Route path="*" name="Home" element={<DefaultLayout />} />
					</Routes>
				</Suspense>
			</HashRouter>
		);
	}
}

export default App;

import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const publicationsSlice = createSlice({
	name: 'publications',
	initialState,
	reducers: {
		setPublicationsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		updatePublicationItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		addPublicationsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removePublicationsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setPublicationsLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setPublicationsList, addPublicationsItem, 
	removePublicationsItem, updatePublicationItem, 
	setPublicationsLoading } = publicationsSlice.actions;

export const fetchPublicationsList = (sendData = {}) => async (dispatch) => {
	try {

		const res = await api.publicationsList(sendData);
		dispatch(setPublicationsList(res));

	} catch (error) {

		return Promise.reject(error);
		
	}
};

export const getPublicationById = (id) => ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {

		const state = getState();

		const list = state.publications.list;

		let item = list.find(item => item.id === id);

		if (item) {

			resolve(item);
			dispatch(addPublicationsItem(item));

		} else {

			api.publicationById(id)
				.then(res => {
					resolve(res);
					dispatch(addPublicationsItem(res));
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Публикация ${id} не найдена`
					}));

					reject();

				});
		}

	});

};

export const reloadPublication = (publicationId) => async (dispatch) => {

	try {

		const publication = await api.publicationById(publicationId);

		if (publication) {
			dispatch(updatePublicationItem(publication));
		}

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось обновить публикацию'
		}));

	}

};

export const editPublication = ({ publicationId, sendData }) => async (dispatch) => {

	dispatch(setPublicationsLoading(true));

	try {
		
		await api.editPublication({ publicationId, sendData });

		dispatch(reloadPublication( publicationId ));

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с изменением стоимости публикации'
		}));

	} finally {

		dispatch(setPublicationsLoading(false));

	}

};


export const unpublishPublication = (publicationId) => async (dispatch) => {

	dispatch(setPublicationsLoading(true));

	try {
		
		await api.unpublishPublication(publicationId);

		dispatch(reloadPublication( publicationId ));

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с изменением стоимости публикации'
		}));

	} finally {

		dispatch(setPublicationsLoading(false));

	}

};

export default publicationsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast, showSuccessToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const webinarsSlice = createSlice({
	name: 'webinars',
	initialState,
	reducers: {
		setWebinarsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		addWebinarsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeWebinarsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		updateWebinarItem: (state, action) => {
			const index = state.list.findIndex((item) => item.id === action.payload.id);
			if (index !== -1) {
				state.list.splice(index, 1, action.payload);
			}
		},
		setWebinarsLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setWebinarsList, addWebinarsItem, removeWebinarsItem, updateWebinarItem, setWebinarsLoading } = webinarsSlice.actions;

export const fetchWebinarList = ( payload ) => async (dispatch) => {

	dispatch(setWebinarsLoading(true));

	try {

		const res = await api.webinarsList( payload );
		dispatch(setWebinarsList(res));

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось получить список вебинаров'
		}));

		return Promise.reject(error);

	} finally {

		dispatch(setWebinarsLoading(false));

	}

};

export const getWebinarById = (id) => (dispatch, getState) => {

	return new Promise((resolve, reject) => {

		const state = getState();

		const list = state.webinars.list;

		let item = list.find((item) => item.id === id);

		if (item) {
			resolve(item);
		} else {
			api.webinarById(id)
				.then((res) => {
					dispatch(addWebinarsItem(res));
					resolve(res);
				})
				.catch(() => {
					dispatch(
						showErrorToast({
							title: 'Ошибка',
							description: `Вебинар ${id} не найден`,
						})
					);

					reject();
				});
		}
	});
};

export const editWebinar = ( webinar ) => async ( dispatch ) => {

	try {

		const res = await api.editWebinar( webinar.id, webinar );

		if (res) {

			await dispatch(reloadWebinar(webinar.id));

		}

	} catch (error) {

		console.error(error);

		dispatch(
			showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось обновить вебинар',
			})
		);

	}

};

export const cancelWebinar = (webinarId) => async (dispatch) => {

	try {

		const res = await api.cancelWebinar( webinarId );

		if (res) {

			await dispatch(reloadWebinar( webinarId ));

		}

	} catch (error) {

		console.error(error);

		dispatch(
			showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось отменить вебинар',
			})
		);

	}


};

export const editWebinarPublication = ( { publicationId, webinarId, payload } ) => async (dispatch) => {

	dispatch( setWebinarsLoading(true) );

	try {

		const res = await api.editWebinarPublication( publicationId, payload );

		if (res && webinarId) {

			await dispatch(reloadWebinar( webinarId ));

		}

	} catch (error) {

		console.error(error);

		dispatch(
			showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось обновить вебинар',
			})
		);

	} finally {

		dispatch( setWebinarsLoading(false) );

	}

};

export const reloadWebinar = (webinarId) => async (dispatch) => {
	
	dispatch(setWebinarsLoading(true));

	try {

		const webinar = await api.webinarById(webinarId);

		if (webinar) {

			dispatch(updateWebinarItem(webinar));

			dispatch(showSuccessToast({
				title: 'Успех',
				description: 'Вебинар обновлен'
			}));


		}

	} catch (e) {

		console.error(e);

		dispatch(
			showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось обновить вебинар',
			})
		);

	} finally {

		dispatch(setWebinarsLoading(false));

	}

};

export const startWebinar = ( webinarId ) => async ( dispatch ) => {

	try {
		
		const res = await api.startWebinar( webinarId );
		
		if (res) {

			dispatch(showSuccessToast({
				title: 'Успех',
				description: 'Уведомление о старте вебинара отправлено'
			}));

			return res;

		}

	} catch (error) {

		console.error(error);

		dispatch(
			showErrorToast({
				description: 'Ошибка при отправке уведомления о старте вебинара',
			})
		);
		
	}

};


export default webinarsSlice.reducer;
